var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c("b-col", [
                    _c("h3", { staticClass: "mb-0 bold" }, [
                      _vm._v("Security Settings"),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
            ],
            1
          ),
          _c("b-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
            _c("h4", { staticClass: "mb-0 bold" }, [_vm._v("Change Password")]),
          ]),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c(
                "div",
                { attrs: { role: "group" } },
                [
                  _c("label", { attrs: { for: "password" } }, [
                    _vm._v("Current Password"),
                  ]),
                  _c("b-form-input", {
                    staticClass: "pl-0",
                    attrs: {
                      id: "password",
                      type: "password",
                      disabled: _vm.processing,
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                ],
                1
              ),
              _c("small", [
                _vm._v("Forgot your password? "),
                _c("a", { attrs: { href: "", tabindex: "-1" } }, [
                  _vm._v("Reset It"),
                ]),
              ]),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c(
                "div",
                { attrs: { role: "group" } },
                [
                  _c("label", { attrs: { for: "npassword" } }, [
                    _vm._v("New Password"),
                  ]),
                  _c("b-form-input", {
                    staticClass: "pl-0",
                    attrs: {
                      id: "npassword",
                      type: "password",
                      disabled: _vm.processing,
                    },
                    model: {
                      value: _vm.npassword,
                      callback: function ($$v) {
                        _vm.npassword = $$v
                      },
                      expression: "npassword",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "text-right mb-5", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticStyle: { "min-width": "150px" },
                  attrs: {
                    pill: "",
                    variant: "primary",
                    disabled: _vm.processing,
                  },
                  on: { click: _vm.updatePassword },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.processing && _vm.actionbutton === "password"
                        ? "Saving"
                        : "Update"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c("b-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
            _c("h4", { staticClass: "mb-0 bold" }, [
              _vm._v("Change Email Address"),
            ]),
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
            _c(
              "div",
              { attrs: { role: "group" } },
              [
                _c("label", { attrs: { for: "nemail" } }, [
                  _vm._v("New Email Address:"),
                ]),
                _c("b-form-input", {
                  staticClass: "pl-0",
                  attrs: {
                    id: "nemail",
                    type: "email",
                    disabled: _vm.processing,
                  },
                  model: {
                    value: _vm.nEmail,
                    callback: function ($$v) {
                      _vm.nEmail = $$v
                    },
                    expression: "nEmail",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c(
                "div",
                { attrs: { role: "group" } },
                [
                  _c("label", { attrs: { for: "cpassword" } }, [
                    _vm._v("Current Password "),
                  ]),
                  _c("b-form-input", {
                    staticClass: "pl-0",
                    attrs: {
                      id: "cpassword",
                      type: "password",
                      disabled: _vm.processing,
                    },
                    model: {
                      value: _vm.cpassword,
                      callback: function ($$v) {
                        _vm.cpassword = $$v
                      },
                      expression: "cpassword",
                    },
                  }),
                ],
                1
              ),
              _c("small", [
                _vm._v("Forgot your password? "),
                _c("a", { attrs: { href: "" } }, [_vm._v("Reset It")]),
              ]),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "text-right mb-5", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticStyle: { "min-width": "150px" },
                  attrs: {
                    pill: "",
                    variant: "primary",
                    disabled: _vm.processing,
                  },
                  on: { click: _vm.updateEmail },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.processing && _vm.actionbutton === "email"
                        ? "Saving"
                        : "Update"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }