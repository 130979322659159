<template>
  <b-card>
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0 bold">Security Settings</h3>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col cols="6">
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>
      </b-col>
      <b-col cols="12" class="mb-4">
        <h4 class="mb-0 bold">Change Password</h4>
        <!-- <small>Est et, tellus nibh lacus orci nec quis. Aliquet etiam odio elementum elit. Id sit lacus et ut porttitor quisque.</small> -->
      </b-col>
      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="password">Current Password</label>

          <b-form-input
            id="password"
            class="pl-0"
            type="password"
            :disabled="processing"
            v-model="password"
          ></b-form-input>
        </div>
        <small
          >Forgot your password? <a href="" tabindex="-1">Reset It</a></small
        >
      </b-col>
      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="npassword">New Password</label>

          <b-form-input
            id="npassword"
            class="pl-0"
            type="password"
            :disabled="processing"
            v-model="npassword"
          ></b-form-input>
        </div>
      </b-col>

      <b-col cols="12" class="text-right mb-5">
        <b-button
          pill
          variant="primary"
          :disabled="processing"
          v-on:click="updatePassword"
          style="min-width: 150px"
          >{{
            processing && actionbutton === "password" ? "Saving" : "Update"
          }}</b-button
        >
      </b-col>

      <b-col cols="12" class="mb-4">
        <h4 class="mb-0 bold">Change Email Address</h4>
        <!-- <small>
          Est et, tellus nibh lacus orci nec quis. Aliquet etiam odio elementum
          elit. Id sit lacus et ut porttitor quisque.
        </small> -->
      </b-col>

      <b-col cols="12" sm="6" md="6">
        <div role="group">
          <label for="nemail">New Email Address:</label>
          <b-form-input
            id="nemail"
            class="pl-0"
            type="email"
            :disabled="processing"
            v-model="nEmail"
          ></b-form-input>
        </div>
      </b-col>
      <b-col cols="12" sm="6" md="6" class="mb-4">
        <div role="group">
          <label for="cpassword">Current Password </label>

          <b-form-input
            id="cpassword"
            class="pl-0"
            type="password"
            :disabled="processing"
            v-model="cpassword"
          ></b-form-input>
        </div>
        <small>Forgot your password? <a href="">Reset It</a></small>
      </b-col>
      <b-col cols="12" class="text-right mb-5">
        <b-button
          pill
          variant="primary"
          :disabled="processing"
          v-on:click="updateEmail"
          style="min-width: 150px"
          >{{
            processing && actionbutton === "email" ? "Saving" : "Update"
          }}</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { UsersService } from "@/services/users.service";
import { AuthService } from "@/services/auth.service";

import { isValidEmail } from "@/utils/string";

export default {
  name: "security",
  data() {
    return {
      password: "",
      npassword: "",
      cpassword: "",
      nEmail: "",
      currentUser: "",
      actionbutton: "",
      processing: false,
      alert: {
        show: false,
        type: "info",
        message: "",
      },
    };
  },
  methods: {
    alertMessage(type, message) {
      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },
    async updatePassword() {
      try {
        this.actionbutton = "password";
        this.processing = true;
        this.alert.show = false;
        if (this.password === "") {
        }
        let data = {
          email: this.currentUser.email,
          password: this.password,
          newpassword: this.npassword,
        };
        const { data: user } = await UsersService.changePassword(data);
        this.alertMessage("success", "Password Changed.");
        this.password = "";
        this.npassword = "";
      } catch (error) {
        this.error =
          error.status === 404
            ? "User with same email not found"
            : error.message;
        this.alertMessage("warning", this.error);
      } finally {
        this.processing = false;
      }
    },
    async updateEmail() {
      try {
        this.actionbutton = "email";
        this.processing = true;
        this.alert.show = false;

        if (!isValidEmail(this.nEmail)) {
          this.alertMessage("warning", "Invalid Email Address");
          return;
        }

        let data = {
          email: this.nEmail,
          password: this.cpassword,
        };
        const user = await UsersService.updateUserEmail(data);
        AuthService.setBearer(user.data.idToken);
        this.alertMessage("success", "Email Address Changed.");
        this.nEmail = "";
        this.cpassword = "";
      } catch (error) {
        this.error =
          error.status === 404
            ? "User with same email not found"
            : error.message;
        this.alertMessage("warning", this.error);
      } finally {
        this.processing = false;
      }
    },
  },
  async mounted() {
    this.currentUser = await this.$store.dispatch("user/getUser");
  },
};
</script>

<style lang="scss" scoped>
label {
  color: #4f4f4f !important;
  font-size: 11px;

  // @include media-breakpoint-up(xxl) {
  //   font-size: (12px * 2) !important;
  // }
}

.card-header {
  border-bottom: 0;
  padding: 3rem 1.5rem;
}

.card-body {
  height: calc(100vh - 260px);
  overflow: auto;

  // @include media-breakpoint-up(xxl) {
  //   height: calc(100vh - 22vh);
  // }
}

@media (max-width: 768px) {
  .card-body {
    height: auto;
  }
}
</style>
